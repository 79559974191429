import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import StaticBanner from "./StaticBanner/StaticBanner";
import ContactCard from "./ContactCard/ContactCard";
import OpeningCard from "./OpeningCard/OpeningCard";
import OfficeProperty from "./OfficeProperty/OfficeProperty";
import ContactSidebar from "./ContactSidebar/ContactSidebar";
import OpinionList from "./OpinionList/OpinionList";
import MarketingServices from "./MarketingServices/MarketingServices";
import OurAdvantage from "./OurAdvantage/OurAdvantage";
import ClientServices from "./ClientServices/ClientServices";
import ServiceAdvisor from "./ServiceAdvisor/ServiceAdvisor";
import ButtonsGroup from "./ButtonsGroup/ButtonGroup";
import Breadcrumbs from "./Breadcrumbs/Breadcrumbs";
import { useWindowSize } from '../../hooks/window-size'
import './Static.scss';

const Static = () => {
	const [windowWidth] = useWindowSize();
	return (
		<React.Fragment>
			<Breadcrumbs/>
		{windowWidth < 768 && <Breadcrumbs/>}
		<StaticBanner/>
		<div className="static-block">
			<Container>
				<Row>
					<Col xl="7" className="col-xxl-8 col-custom-8">
						<Row>
							<Col md="5">
								<ContactCard/>
							</Col>
							<Col md="6">
								<OpeningCard/>
							</Col>
					</Row>
						<Row>
								<Col>
								<OfficeProperty/>
								</Col>
						</Row>
					</Col>
					<Col xl="5" className="col-xxl-4 col-custom-4">
						<ContactSidebar/>
						<OpinionList/>
					</Col>
				</Row>

			</Container>
		</div>
		<MarketingServices/>
		<OurAdvantage/>
		<ClientServices/>
		<ServiceAdvisor/>
		<ButtonsGroup/>
		</React.Fragment>
	)
}

export default Static
