import React from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Static from '../../Components/Static/Static';

const StaticOne=()=>{

    return(
        <React.Fragment>
            <div className="wrapper static-wrapper">
                <Header/>
                <div className="static-page">
                     <Static />
                </div>
                <Footer />
            </div>
        </React.Fragment>
    )
}

export default StaticOne;