import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col,Form,Button } from 'react-bootstrap';

import './ButtonsGroup.scss';

const ButtonsGroup = () => {
	return (
		<div className="btn-groups d-md-none">
			<Container>
				<Row>
					<Col sm="12" className="d-flex justify-content-between">
					<Link href="#" className="btn">
                    CONTACT US
                    </Link>
                    <Link href="#" className="btn">
                    BOOK VALUATION
                    </Link>
                    <Link href="tel:xxxx" className="btn-primary">
                        <i className="icon-phone-gray"></i>
                    </Link>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default ButtonsGroup
