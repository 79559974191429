import React, { useState } from "react";
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
// import ScrollAnimation from 'react-animate-on-scroll';
import IMG1 from '../../../images/img-1.jpg'
import PlayVideo from '../../PlayVideo/PlayVideo';
import './ClientServices.scss';

const ClientServices = () => {
	const [isPlay, setPlay] = useState(false);
	return (
		<div className="client-services">
			<Container>
				<Row>
					<Col lg="6">
					{/* <ScrollAnimation animateIn="fadeInLeft"> */}
						<picture className="img-zoom">
                             <source media="(min-width:1800px)" srcSet={IMG1} />
                             <source media="(min-width:992px)" srcSet={IMG1} />
                             <source media="(min-width:768px)" srcSet={IMG1} />
                            <img src={IMG1} alt="" />
							<button type="button" className="play-btn" onClick={()=> {setPlay(true)}}>
                                            <i className="icon-play"></i>
                                        </button>
                        </picture>
					{/* </ScrollAnimation> */}
					</Col>
					<Col lg="6">
					{/* <ScrollAnimation animateIn="fadeInRight"> */}
					<div className="services-content">
						<h2>dng have the Only Client Service Divison in Ireland</h2>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis felis nulla, finibus et porttitor quis, cursus non erat. Donec ut ex id elit congue dignissim eu et ex. Nam eget mi sit amet quam dictum vestibulum.</p>
					</div>
					<div className="accordion-wrap">
				<ul>
					<li>
						<div className="accordion-header plus">
								Subheading
							<i className="icon-plus"></i>
							<i className="icon-minus"></i>
						</div>
						<div className="accordion-content hide">
							<span className="accordion-info">BER: D2</span>
							<span className="accordion-info">BER No: 100408152</span>
							<span className="accordion-info">Performance Indicator: 296.21 kWh/m?/yr</span>
						</div>
					</li>
					<li>
						<div className="accordion-header plus">
						Subheading
							<i className="icon-plus"></i>
							<i className="icon-minus"></i>
						</div>
						<div className="accordion-content hide">
							<span className="accordion-info">BER: D2</span>
							<span className="accordion-info">BER No: 100408152</span>
							<span className="accordion-info">Performance Indicator: 296.21 kWh/m?/yr</span>
						</div>
					</li>
					<li>
						<div className="accordion-header plus">
						Subheading
							<i className="icon-plus"></i>
							<i className="icon-minus"></i>
						</div>
						<div className="accordion-content hide">
							<span className="property-info">BER: D2</span>
							<span className="property-info">BER No: 100408152</span>
							<span className="property-info">Performance Indicator: 296.21 kWh/m?/yr</span>
						</div>
					</li>
					
				</ul>
				</div>
				{/* </ScrollAnimation> */}
					</Col>
				</Row>
			</Container>
			<PlayVideo isOpen={isPlay} stopPlay={setPlay} videoId="V8ksSGxo6no"/>
		</div>
	)
}

export default ClientServices
